import React, { Suspense, useContext } from 'react';
import {
	BrowserRouter as Router,
	Route,
	Redirect,
	Switch,
	NavLink,
	useLocation,
} from 'react-router-dom'; // renames BrowserRouter to just Router
import clsx from 'clsx';

import { AuthContext } from '../../context/auth-context';
//import { useAuth } from '../../hooks/auth-hook';
import LinearIndeterminate from '../../components/UIElements/LinearIndeterminate';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
	Drawer,
	AppBar,
	Toolbar,
	List,
	Button,
	CssBaseline,
	Typography,
	Divider,
	IconButton,
	ListItem,
	ListItemIcon,
	ListItemText,
	BottomNavigation,
	BottomNavigationAction,
	Hidden,
} from '@material-ui/core';
import LibraryBooksRoundedIcon from '@material-ui/icons/LibraryBooksRounded';
import MenuIcon from '@material-ui/icons/Menu';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import SettingsIcon from '@material-ui/icons/Settings';
import GroupIcon from '@material-ui/icons/Group';

const Insights = React.lazy(() => import('../../../bets/pages/Insights'));
/*const InsightsPublic = React.lazy(() =>
	import('../../../bets/pages/InsightsPublic')
);*/
const NewBetFormik = React.lazy(() =>
	import('../../../bets/pages/NewBetFormik')
);
const NewBetBulk = React.lazy(() => import('../../../bets/pages/NewBetBulk'));
const UserBets = React.lazy(() => import('../../../bets/pages/UserBets'));
const Bet = React.lazy(() => import('../../../bets/pages/Bet'));
const Auth = React.lazy(() => import('../../../user/pages/Auth'));
const Reset = React.lazy(() => import('../../../user/pages/Reset'));
const ResetPassword = React.lazy(() =>
	import('../../../user/pages/ResetPassword')
);
const Settings = React.lazy(() => import('../../../user/pages/Settings'));
const Groups = React.lazy(() => import('../../../groups/pages/Groups'));
const JoinGroup = React.lazy(() => import('../../../groups/pages/JoinGroup'));
const Group = React.lazy(() => import('../../../groups/pages/Group'));

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		backgroundColor: '#212121',
	},
	logo: {
		textDecoration: 'none',
		color: theme.palette.secondary.contrastText,
	},
	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	menuButton: {
		marginRight: 36,
	},
	title: {
		flexGrow: 1,
		fontFamily: 'Montserrat',
		fontSize: '1.5rem',
	},
	hide: {
		display: 'none',
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: 'nowrap',
	},
	drawerOpen: {
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerClose: {
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: 'hidden',
		width: theme.spacing(7) + 1,
		[theme.breakpoints.up('sm')]: {
			width: theme.spacing(9) + 1,
		},
	},
	toolbar: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: theme.spacing(0, 1),
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
	},
	BottomNavigation: {
		width: '100%',
		position: 'fixed',
		bottom: 0,
		zIndex: 1000,
		backgroundColor: '#212121',
		boxShadow:
			'0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
	},
}));

const MiniDrawer = (props) => {
	const auth = useContext(AuthContext);
	const classes = useStyles();
	const theme = useTheme();
	const [value, setValue] = React.useState(0);
	const [open, setOpen] = React.useState(false);

	const handleDrawerOpen = () => {
		setOpen(true);
		window.dataLayer.push({
			event: 'MiniDrawer',
			action: 'open',
			status: 'success',
		});
	};

	const handleDrawerClose = () => {
		setOpen(false);
		window.dataLayer.push({
			event: 'MiniDrawer',
			action: 'close',
			status: 'success',
		});
	};

	const updateBottomNav = (value) => {
		setValue(value);
	};

	let routes;

	if (auth.token) {
		routes = (
			<Switch>
				<Route path="/" exact>
					<Insights />
				</Route>
				<Route path="/bets" exact>
					<UserBets />
				</Route>
				<Route path="/bets/new" exact>
					<NewBetFormik updateBottomNav={updateBottomNav} />
				</Route>
				<Route path="/bets/new-bulk" exact>
					<NewBetBulk />
				</Route>
				<Route path="/groups" exact>
					<Groups />
				</Route>
				<Route path="/groups/join/:groupId/:adminId">
					<JoinGroup />
				</Route>
				<Route path="/user/settings" exact>
					<Settings />
				</Route>
				<Route path="/bets/user/:userId">
					<UserBets view="public" />
				</Route>
				<Route path="/bets/:betId">
					<Bet />
				</Route>
				<Route path="/groups/:groupId">
					<Group updateBottomNav={updateBottomNav} />
				</Route>
				<Route path="/bets/:betId">
					{/* has to be placed after the places/new route, otherwise react would think /new is a placeId */}
					{/* <UpdatePlace /> */}
				</Route>
				<Route path="/insights/user/:userId">
					<Insights view="public" />
				</Route>

				<Redirect to="/" />
				{/* if no path matches any of the above, then redirect to the index */}
			</Switch>
		);
	} else {
		routes = (
			<Switch>
				<Route path="/auth" exact>
					<Auth />
				</Route>
				<Route path="/auth/reset" exact>
					<Reset />
				</Route>
				<Route path="/auth/reset/:token" exact>
					<ResetPassword />
				</Route>

				<Route path="/insights/user/:userId" exact>
					<Insights view="public" />
				</Route>
				<Route path="/bets/user/:userId">
					<UserBets view="public" />
				</Route>
				<Route path="/bets/:betId">
					<Bet />
				</Route>
				<Route path="/groups/join/:groupId/:adminId">
					<JoinGroup />
				</Route>
				<Route path="/groups/:groupId">
					<Group />
				</Route>
				<Redirect to="/auth" />
			</Switch>
		);
	}

	return (
		<Router>
			<div className={classes.root}>
				<CssBaseline />

				<AppBar
					position="fixed"
					color="default"
					className={clsx(classes.appBar, {
						[classes.appBarShift]: open,
					})}
				>
					<Toolbar>
						<Hidden xsDown>
							{auth.isLoggedIn && (
								<IconButton
									color="inherit"
									aria-label="open drawer"
									onClick={handleDrawerOpen}
									edge="start"
									className={clsx(classes.menuButton, {
										[classes.hide]: open,
									})}
								>
									<MenuIcon />
								</IconButton>
							)}
						</Hidden>

						<Typography variant="h6" className={classes.title} noWrap>
							<NavLink className={classes.logo} to="/">
								Hedge
							</NavLink>
						</Typography>

						{!auth.isLoggedIn && (
							<React.Fragment>
								<Button component={NavLink} to="/auth" color="inherit">
									Login / Signup
								</Button>
							</React.Fragment>
						)}
						{auth.isLoggedIn && (
							<React.Fragment>
								<Button onClick={auth.logout} color="inherit">
									Logout
								</Button>
							</React.Fragment>
						)}
					</Toolbar>
				</AppBar>

				{auth.isLoggedIn && (
					<React.Fragment>
						<Hidden xsDown>
							<Drawer
								variant="permanent"
								className={clsx(classes.drawer, {
									[classes.drawerOpen]: open,
									[classes.drawerClose]: !open,
								})}
								classes={{
									paper: clsx({
										[classes.drawerOpen]: open,
										[classes.drawerClose]: !open,
									}),
								}}
							>
								<div className={classes.toolbar}>
									<IconButton onClick={handleDrawerClose}>
										{theme.direction === 'rtl' ? (
											<ChevronRightIcon />
										) : (
											<ChevronLeftIcon />
										)}
									</IconButton>
								</div>
								<Divider />
								<List>
									{['Insights', 'New Bet', 'Bets', 'Groups', 'Settings'].map(
										(text, index) => (
											<ListItem
												button
												component={NavLink}
												to={
													index === 0
														? '/'
														: index === 1
														? '/bets/new'
														: index === 2
														? `/bets`
														: index === 3
														? '/groups'
														: index === 4
														? '/user/settings'
														: '/'
												}
												key={text}
											>
												<ListItemIcon>
													{index === 0 ? (
														<TrendingUpIcon />
													) : index === 1 ? (
														<AddCircleIcon />
													) : index === 2 ? (
														<LibraryBooksRoundedIcon />
													) : index === 3 ? (
														<GroupIcon />
													) : (
														<SettingsIcon />
													)}
												</ListItemIcon>
												<ListItemText primary={text} />
											</ListItem>
										)
									)}
								</List>
							</Drawer>
						</Hidden>
						<Hidden smUp>
							<BottomNavigation
								value={value}
								onChange={(event, newValue) => {
									setValue(newValue);
								}}
								showLabels
								className={classes.BottomNavigation}
							>
								<BottomNavigationAction
									component={NavLink}
									to="/"
									label="Insights"
									icon={<TrendingUpIcon />}
								/>
								<BottomNavigationAction
									component={NavLink}
									to="/bets/new"
									label="New Bet"
									icon={<AddCircleIcon />}
								/>
								<BottomNavigationAction
									component={NavLink}
									to={`/bets`}
									label="Bets"
									icon={<LibraryBooksRoundedIcon />}
								/>
								<BottomNavigationAction
									component={NavLink}
									to={`/groups`}
									label="Groups"
									icon={<GroupIcon />}
								/>
								<BottomNavigationAction
									component={NavLink}
									to={`/user/settings`}
									label="Settings"
									icon={<SettingsIcon />}
								/>
							</BottomNavigation>
						</Hidden>
					</React.Fragment>
				)}

				<main className={classes.content}>
					<div className={classes.toolbar} />
					<Suspense
						fallback={
							<div className="center">
								<LinearIndeterminate />
							</div>
						}
					>
						{routes}
					</Suspense>
				</main>
			</div>
		</Router>
	);
};

export default MiniDrawer;
