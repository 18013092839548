import React, { useEffect, useState } from 'react';
//import { browserHistory } from 'react-router';
import axios from 'axios';

import MiniDrawer from './shared/components/Navigation/MiniDrawer';
import { AuthContext } from './shared/context/auth-context';
import { useAuth } from './shared/hooks/auth-hook';
import './App.css';

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

let darkTheme = createMuiTheme({
	palette: {
		type: 'dark',
		primary: {
			light: '#46ffff',
			main: '#18ffff',
			dark: '#10b2b2',
			contrastText: '#303030',
		},
		secondary: {
			light: '##9670ff',
			main: '#7c4dff',
			dark: '#5635b2',
			contrastText: '#FFF',
		},
	},
	typography: {
		fontFamily: [
			'Ubuntu',
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(','),
	},
});

darkTheme.typography.h1 = {
	fontSize: '1.3rem',
	[darkTheme.breakpoints.up('sm')]: {
		fontSize: '1.8rem',
	},
	[darkTheme.breakpoints.up('lg')]: {
		fontSize: '2.5rem',
	},
};

const App = () => {
	const { token, login, logout, userId } = useAuth();
	const [loadedUserName, setLoadedUserName] = useState();
	const [error, setError] = useState('');
	/*userId &&
		window.gtag('config', process.env.GA_PROPERTY_ID, {
			user_id: userId,
		});*/

	useEffect(() => {
		userId &&
			window.dataLayer.push({
				event: 'userProps',
				action: 'read',
				status: 'success',
				userId: userId,
			});
	}, [userId]);

	//const userId = auth.userId;
	//console.log(userId, auth);

	useEffect(() => {
		const fetchUser = async () => {
			axios({
				method: 'get',
				url: `${process.env.REACT_APP_BACKEND_URL}/users/${userId}`,
			})
				.then(function (response) {
					if (response.status === 200) {
						setLoadedUserName(response.data.name);
						console.log(response.data.user.name);
					}
				})
				.catch(function (error) {
					setError(error.message);
				});
		};
		fetchUser();
	}, [axios, userId, setLoadedUserName]);

	/*useEffect(() => {
		loadedUserName &&
			window.Intercom('boot', {
				app_id: 'd8ldhq4b',
				name: loadedUserName,
				user_id: userId,
				//email: 'Test Email', // Email address
				//created_at: 'Test time', // Signup date as a Unix timestamp
			});
	}, [userId, loadedUserName]);*/

	return (
		<AuthContext.Provider
			value={{
				isLoggedIn: !!token, // converted to true if there is a token, false if null or otherwise
				token: token,
				userId: userId,
				login: login,
				logout: logout,
			}}
		>
			<ThemeProvider theme={darkTheme}>
				{/* allows every component to see if someone is logged in or not, will rerender all children who use it when this value changes */}
				<MiniDrawer />
			</ThemeProvider>
		</AuthContext.Provider>
	);
};

export default App;
