import { useState, useCallback, useEffect } from 'react';

let logoutTimer;

export const useAuth = () => {
	const [token, setToken] = useState(false);
	const [tokenExpirationDate, setTokenExpirationDate] = useState();
	const [userId, setUserId] = useState(false);

	const login = useCallback((uid, token, expirationDate) => {
		setToken(token);
		setUserId(uid);
		// this is just a local variable, separate from the state
		const tokenExpirationDate =
			expirationDate ||
			new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 24); //* 60 * 60); // 1000 milliseconds * 60 seconds * 60 minutes & 24 hours * 3 = plus 3 days
		setTokenExpirationDate(tokenExpirationDate);
		localStorage.setItem(
			'userData',
			JSON.stringify({
				userId: uid,
				token: token,
				expiration: tokenExpirationDate.toISOString(), // special type of string that holds all of the date info and can be converted back to a date later
			})
		);
	}, []);

	const logout = useCallback(() => {
		setToken(null);
		setTokenExpirationDate(null);
		setUserId(null);

		window.dataLayer.push({
			event: 'logout',
			action: 'auth',
			status: 'success',
		});

		localStorage.removeItem('userData');
	}, []);

	useEffect(() => {
		if (token && tokenExpirationDate) {
			const remainingTime =
				tokenExpirationDate.getTime() - new Date().getTime(); // using .getTime gives us the difference in milliseconds
			// setTimeout is a vannila js function
			logoutTimer = setTimeout(logout, remainingTime);
		} else {
			clearTimeout(logoutTimer);
		}
	}, [token, logout, tokenExpirationDate]);

	// useEffect here is to run some logic every time the app starts. Must be called after login has been defined above
	useEffect(() => {
		const storedData = JSON.parse(localStorage.getItem('userData')); // JSON.parse converts JSON strings back to objects
		if (
			storedData &&
			storedData.token &&
			new Date(storedData.expiration) > new Date()
		) {
			login(
				storedData.userId,
				storedData.token,
				new Date(storedData.expiration)
			);
		}
	}, [login]);

	return { token, login, logout, userId };
};
